.calender-listings {
  position: relative;
  height: 100%;
  &::before {
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
    border-left: 1px solid rgba(199, 224, 253, 0.4);
    content: "";
  }
  .react-calendar {
    background: transparent;
    border: 0;
    position: relative;
    margin-bottom: 50px;
    &::before {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 90%;
      height: 1px;
      bottom: -25px;
      background: rgba(199, 224, 253, 0.4);
      content: "";
    }
    .react-calendar__navigation {
      padding: 0 15px;
      margin-bottom: 20px;
      button {
        padding: 0;
        &:hover {
          background: transparent;
        }
        &:nth-child(1),
        &:nth-child(3) {
          font-size: 0;
          min-width: 0;
          position: relative;
          font-family: "icomoon" !important;
          speak: never;
          font-style: normal;
          font-weight: normal;
          font-feature-settings: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          &:before {
            content: "\e907";
            position: absolute;
            font-weight: bold;
            font-size: 0.9rem;
            color: var(--fonts-primary);
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) rotate(90deg);
          }
        }
        &:nth-child(2) {
          order: 1;
          text-align: left;
          font-weight: bold;
          font-size: 1.6rem;
          color: var(--fonts-primary);
          font-family: "Avenir-Regular";
        }
        &:nth-child(1) {
          order: 2;
        }
        &:nth-child(3) {
          order: 3;
          margin-left: 30px;
          &:before {
            transform: translate(-50%, -50%) rotate(-90deg);
          }
        }
      }
    }
    &__month-view {
      &__weekdays {
        margin-bottom: 10px;
        &__weekday {
          font-weight: 500;
          font-size: 1.1rem;
          color: var(--fonts-dark);
          font-family: "Avenir-Regular";
          text-transform: capitalize;
          abbr {
            text-decoration: none;
          }
        }
      }
      &__days button {
        font-weight: 500;
        font-size: 1.1rem;
        color: #666666;
        font-family: "Avenir-Regular";
        padding: 15px 5px;
        &:hover {
          background-color: transparent;
        }
        &.react-calendar__tile--active {
          position: relative;
          color: #fff;
          background: transparent;
          &:before {
            content: "";
            background: linear-gradient(270deg, #ff5f3a 0%, #ff8267 100%);
            width: 45px;
            height: 45px;
            position: absolute;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          abbr {
            position: relative;
            color: #fff;
          }
        }
        &.react-calendar__tile--now {
          // background: transparent;
          color: #fff;
          border-radius: 6px;
          background: linear-gradient(270deg, #ff5f3a 0%, #ff8267 100%);
          // padding: 5px;
          // color: var(--primary);
        }
      }
    }
  }
  .empty-exam {
    display: flex;
    align-items: center;
    flex-direction: column;

    .img-blk {
      margin: 70px 0 35px 0;
    }
    h2 {
      font-size: 1.1rem;
      color: #788093;
      font-weight: bold;
      margin-bottom: 10px;
    }
    h3 {
      font-size: 0.85rem;
      color: #788093;
    }
  }
  .activity-card {
    padding: 15px;
    background: #ffffff;
    border-radius: 4px;
    margin-left: 20px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 4px;
      background: #90b0d7;
      border-radius: 4px 0px 0px 4px;
    }
    h2 {
      font-size: 1rem;
      color: var(--fonts-primary);
      font-weight: bold;
    }
    h3 {
      font-size: 0.75rem;
      color: var(--fonts-primary);
    }
    h4 {
      font-size: 0.75rem;
      color: var(--fonts-primary);
      span {
        font-weight: bold;
      }
    }
  }

  .highlighted_dates {
    color: #ff785a !important;
  }

  .today_highlighted_dates {
    color: #ffffff !important;
  }
}
