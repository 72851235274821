:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --color-primary: #ff6b4a;
  --fonts-primary: #414b5f;
  --fonts-secondary: #788093;
  --fonts-light: #989aa2;
  --fonts-gray: #979797;
  --fonts-dark: #333333;
  --btn-primary-bg: #23417a;
  --btn-primary-bg-hover: #375a9f;
  --fonts-white: #fff;
  --fonts-golden: #ff9823;
  --primary: #ff6b4a;
  --green: #00cc98;
}
