.schedule-calendar {
    font-family: 'Avenir-Regular';
    .calendar-top-bar {
      position: absolute;
      right: 35px;
      top: 110px;
      @media(max-width:767px) {
        right: 0px;
        top: 86px;
      }
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          font-size: 0.75rem;
          color: var(--fonts-primary);
          margin-right: 20px;
          padding-left: 20px;
          position: relative;
          // display: inline-block;
          &:first-of-type {
            &::before {
              background: #90b0d7;
            }
          }
          &:last-of-type {
            &::before {
              background: var(--primary);
            }
          }
          &::before {
            position: absolute;
            content: "";
            width: 16px;
            height: 7px;
            background: #e8c842;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
    .rbc-toolbar {
        justify-content: space-between;
        margin-bottom: 20px;
        .rbc-btn-group {
          &:first-of-type {
            display: flex;
            button {
              background: #f4f4f5;
              height: 28px;
              border: 0;
              cursor: pointer;
              box-shadow: none;
              &:nth-child(1) {
                order: 2;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 65px;
                font-size: 0.75rem;
                color: var(--fonts-primary);
                border-radius: 0;
                margin: 0 3px 0 2px;
              }
              &:nth-child(2),
              &:nth-child(3) {
                font-size: 0;
                position: relative;
                font-family: "icomoon" !important;
                speak: never;
                font-style: normal;
                font-weight: normal;
                font-feature-settings: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                &:before {
                  content: "\e907";
                  position: absolute;
                  font-weight: bold;
                  font-size: 0.8rem;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%) rotate(90deg);
                }
              }
              &:nth-child(2) {
                order: 1;
                border-radius: 6px 0px 0px 6px;
              }
              &:nth-child(3) {
                order: 3;
                border-radius: 0px 6px 6px 0px;
                &:before {
                  transform: translate(-50%, -50%) rotate(-90deg);
                }
              }
            }
          }
          &:last-of-type {
            button {
                border:0;
                border-radius: 8px;
                transition: all ease 0.3s;
                padding: 4px 16px;
                font-size: .85rem;
                color: #71717A;
                font-weight: 500;
                font-family: "Avenir-Regular";
                margin-left: 2px;
                @media(max-width:767px) {
                  padding: 4px;
                }
                cursor: pointer;
                &.rbc-active , &:hover{
                    background: var(--color-primary);
                    color: #fff;
                    box-shadow: none;
                }
            }
          }
        }
        .rbc-toolbar-label {
          color: var(--fonts-primary);
          font-size: 1.1rem;
          font-weight: bold;
          @media(max-width:767px) {
            font-size: .85rem;
          }
        }
    }
    .rbc-month-view {
      border: 0;
      .rbc-month-header .rbc-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 64px;
        border-left: 1px solid rgba(199, 224, 253, 0.72);
        border-top: 1px solid rgba(199, 224, 253, 0.72);
        border-bottom: 0;
        color: #333333;
        font-weight: bold;
        font-size: 1rem;
        @media(max-width:767px) {
          height: 44px;
          font-size: .75rem;
        }
        &:last-of-type {
          border-right: 1px solid rgba(199, 224, 253, 0.72);
        }
      }
      .rbc-month-row {
        border: 0;
        min-height: 77px;
        @media(max-width:767px) {
          min-height: 50px;
        }
        &:last-of-type {
          .rbc-date-cell {
            border-bottom: 1px solid rgba(199, 224, 253, 0.72);
          }
        }
        .rbc-day-bg {
          border: 0;
          &.rbc-today {
            background: rgba(137, 180, 231, 0.66);
            opacity: 0.2;
          }
        }
        .rbc-row {
          &:nth-child(2),
          &:nth-child(4),
          &:nth-child(3) {
            position: absolute;
            width: 100%;
            top: 0;
            .rbc-row-segment {
              justify-content: flex-end;
              display: flex;
              .rbc-event {
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background: #90b0d7;
                font-size: 0.65rem;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: normal;
                margin: 7px 7px 0 0;
                z-index: 99;
                transition: all ease .3s;
                // &:hover {
                //   background: #fff;
                //   color:#90b0d7; 
                // }
              }
            }
          }
          &:nth-child(3) {
            .rbc-row-segment {
              position: relative;
              right: 20px;
            }
          }
          &:nth-child(4) {
            top: 40px;
          }
          .rbc-date-cell {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 77px;
            border-left: 1px solid rgba(199, 224, 253, 0.72);
            border-top: 1px solid rgba(199, 224, 253, 0.72);
            @media(max-width:767px) {
              height: 50px;
            }
            &.rbc-now {
              border-top: solid 3px var(--primary);
              button {
                color: var(--primary);
              }
            }
            button {
              color: #666666;
              font-size: 1.1rem;
              font-family: "DM Sans", sans-serif;
            }
            &:last-of-type {
              border-right: 1px solid rgba(199, 224, 253, 0.72);
            }
            &.rbc-off-range {
              background: #fff;
              opacity: 1;
              color: white;
              z-index: 10000;
              button {
                display: none;
              }
            }
          }
        }
      }
    }
    .rbc-time-view {
      border: 0;
      .rbc-time-header {
        border:0;
        .rbc-time-header-content {
          .rbc-time-header-cell {
            .rbc-header {
              height: 64px;
              border-bottom:0;
              background-color: #fff;
              text-align: left;
              padding: 10px 0 0 10px;
              border-bottom: 1px solid #ddd;
              &:first-of-type ,&:last-of-type {
                background: #FAFAFA;
              }
              @media(max-width:767px) {
                height: 44px;
                padding: 10px 0 0 4px;
              }
              .rbc-button-link {
                font-family: 'Avenir-Regular';
                font-size: 1rem;
                font-weight: bold;
                @media(max-width:767px) {
                  font-size: .75rem
                }
              }

            }
          }
          .rbc-allday-cell {
            // display: none;
            height: 36px;
            
            .rbc-event {
              width: 18px;
              height: 18px;
              border-radius: 50%;
              background: #90b0d7;
              font-size: 0.65rem;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: normal;
              margin: 7px;
              z-index: 99;
              transition: all ease .3s;
              // &:hover {
              //   background: #fff;
              //   color:#90b0d7; 
              // }
            }
          }
        }
      }
      .rbc-time-content {
        border:0;
        .rbc-day-slot {
          &:nth-child(2) ,&:last-of-type {
            .rbc-timeslot-group {
              background: #FAFAFA;
            }
            
          }
        }
        .rbc-time-gutter {
          .rbc-timeslot-group {
            border: 0;
            background: transparent;
            color: #71717A;
            font-size: .65rem;
            text-align: left;
          }
        }
        .rbc-timeslot-group {
          // min-height: 70px;
          background: #fff;
          .rbc-time-slot {
            border: 0;
          }
        }
      }
    }
}
