.sidebarActive.app-header + .MuiDrawer-root,
.css-zpv1eh + .MuiDrawer-root {
  .MuiDrawer-paper {
    .app-logo,
    .student-info {
      opacity: 1;
    }
    .MuiList-root {
      .MuiListItemButton-root {
        padding: 12px 0 12px 30px;
        position: relative;
        transition: all ease 0.3s;
        .MuiListItemText-root {
          display: block;
        }
      }
    }
  }
}
.app-header:not(.sidebarActive) + .MuiDrawer-root .MuiDrawer-paper {
  @media (max-width: 767px) {
    width: 0;
  }
}
.MuiDrawer-root {
  position: fixed;
  z-index: 9999;
}
.MuiDrawer-paper {
  padding: 30px 0 0 0;
  border-color: rgba(231, 240, 250, 0.5) !important;
  .sidebar-top-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    padding: 0 30px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
    .icon-close {
      display: none;
      @media (max-width: 767px) {
        display: block;
        font-size: 26px;
      }
    }
    .app-logo {
      max-width: 150px;
      display: block;
      transition: all ease 0.4s;
      opacity: 0;
    }
  }

  .MuiList-root {
    padding: 0;
    max-height: calc(100% - 205px);
    overflow-y: auto;
    overflow-x: hidden;
    .MuiListItemButton-root {
      padding: 12px;
      margin-bottom: 12px;
      justify-content: center;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      &:before {
        content: "";
        position: absolute;
        right: 0;
        width: 4px;
        height: 0%;
        top: 50%;
        transition: all ease 0.5s;
        background: linear-gradient(270deg, #ff6b4a 0%, #ff8267 100%);
        box-shadow: -2px 0px 7px rgba(255, 148, 128, 0.6);
        border-radius: 1px;
        transform: translateY(-50%);
      }
      &:hover,
      &.route-active {
        background: linear-gradient(
          270deg,
          #fcfaf6 0%,
          rgba(252, 250, 246, 0) 104.32%
        );
        border-color: rgba(238, 238, 238, 0.7);
        &:before {
          height: 100%;
        }
        .MuiListItemText-root,
        .sidebar-icon {
          color: #ff6b4a;
        }
      }
      .sidebar-icon {
        font-size: 1.5rem;
        color: #414b5f;
        transition: all ease 0.3s;
      }
      .MuiListItemIcon-root {
        min-width: auto;
      }
      .MuiListItemText-root {
        color: var(--fonts-secondary);
        margin: 0 0 0 20px;
        display: none;
        transition: all ease 0.3s;
        span {
          line-height: normal;
        }
      }
    }
  }
}
