.calendar-wrap {
    padding: 24px;
    background: #fff;
    border: 1px solid rgba(199, 224, 253, 0.43);
    border-radius: 8px;
    height: 100%;
    position: relative;
    height: 642px;
    @media(max-width:767px) {
      height: 442px;
      padding: 16px;
    }
    .calendar-top-bar {
      position: absolute;
      right: 25px;
      top: 60px;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          font-size: 0.65rem;
          color: var(--fonts-primary);
          margin-right: 20px;
          padding-left: 20px;
          position: relative;
          display: inline-block;
          @media(max-width:767px) {
            margin-right: 10px;
          }
          &:first-of-type {
            &::before {
              background: var(--primary);
            }
          }
          &:last-of-type {
            &::before {
              background: #e8c842;
            }
          }
          &::before {
            position: absolute;
            content: "";
            width: 16px;
            height: 7px;
            background: #90b0d7;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
    .rbc-toolbar {
      justify-content: space-between;
      flex-direction: row-reverse;
      margin-bottom: 40px;
      .rbc-btn-group {
        &:first-of-type {
          display: flex;
          button {
            background: #f4f4f5;
            height: 28px;
            border: 0;
            cursor: pointer;
            box-shadow: none;
            &:nth-child(1) {
              order: 2;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 65px;
              font-size: 0.75rem;
              color: var(--fonts-primary);
              border-radius: 0;
              margin: 0 3px 0 2px;
            }
            &:nth-child(2),
            &:nth-child(3) {
              font-size: 0;
              position: relative;
              font-family: "icomoon" !important;
              speak: never;
              font-style: normal;
              font-weight: normal;
              font-feature-settings: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              &:before {
                content: "\e907";
                position: absolute;
                font-weight: bold;
                font-size: 0.8rem;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) rotate(90deg);
              }
            }
            &:nth-child(2) {
              order: 1;
              border-radius: 6px 0px 0px 6px;
            }
            &:nth-child(3) {
              order: 3;
              border-radius: 0px 6px 6px 0px;
              &:before {
                transform: translate(-50%, -50%) rotate(-90deg);
              }
            }
          }
        }
        &:last-of-type {
          display: none;
        }
      }
      .rbc-toolbar-label {
        text-align: left;
        color: var(--fonts-primary);
        font-size: 1.1rem;
        font-weight: bold;
      }
    }
    .rbc-month-view {
      border: 0;
      .rbc-month-header .rbc-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 64px;
        border-left: 1px solid rgba(199, 224, 253, 0.72);
        border-top: 1px solid rgba(199, 224, 253, 0.72);
        border-bottom: 0;
        color: #333333;
        font-weight: bold;
        font-size: 1rem;
        @media(max-width:767px) {
          font-size: .75rem;
        }
        &:last-of-type {
          border-right: 1px solid rgba(199, 224, 253, 0.72);
        }
      }
      .rbc-month-row {
        border: 0;
        max-height: 77px;
        @media(max-width:767px) {
          max-height: 44px
        }
        &:last-of-type {
          .rbc-date-cell {
            border-bottom: 1px solid rgba(199, 224, 253, 0.72);
          }
        }
        .rbc-day-bg {
          border: 0;
          &.rbc-today {
            background: rgba(137, 180, 231, 0.66);
            opacity: 0.2;
          }
        }
        .rbc-row {
          &:nth-child(2),
          &:nth-child(4),
          &:nth-child(3) {
            position: absolute;
            width: 100%;
            top: 0;
            .rbc-row-segment {
              justify-content: flex-end;
              display: flex;
              .rbc-event {
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background: #90b0d7;
                font-size: 0.65rem;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: normal;
                margin: 7px 7px 0 0;
                z-index: 99;
                transition: all ease .3s;
                @media(max-width:767px) {
                  width: 12px;
                  height: 12px;
                  margin: 2px 2px 0 0;
                }
                // &:hover {
                //   background: #fff;
                //   color:#90b0d7; 
                // }
              }
            }
          }
          &:nth-child(3) {
            .rbc-row-segment {
              position: relative;
              right: 20px;
            }
          }
          &:nth-child(4) {
            top: 40px;
          }
          .rbc-date-cell {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 77px;
            border-left: 1px solid rgba(199, 224, 253, 0.72);
            border-top: 1px solid rgba(199, 224, 253, 0.72);
            @media(max-width:767px) {
              height: 44px
            }
            &.rbc-now {
              border-top: solid 3px var(--primary);
              button {
                color: var(--primary);
              }
            }
            button {
              color: #666666;
              font-size: 1.1rem;
              font-family: "DM Sans", sans-serif;
            }
            &:last-of-type {
              border-right: 1px solid rgba(199, 224, 253, 0.72);
            }
            &.rbc-off-range {
              background: #fff;
              opacity: 1;
              color: white;
              z-index: 10000;
              button {
                display: none;
              }
            }
          }
        }
      }
    }
  }