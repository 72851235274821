.filter-popup {
  .filter-options-blk {
    height: calc(100% - 75px);
    overflow: auto;
    .filter-option {
      margin-bottom: 30px;
      &:last-of-type {
        margin-bottom: 0;
      }
      h2 {
        font-size: 1rem;
        font-weight: 800;
        color: var(--fonts-primary);
        margin-bottom: 20px;
      }
      .option {
        color: #7d8390;
        font-weight: 500;
        font-size: 0.85rem;
        border-radius: 6px;
        height: 35px;
        padding: 0 24px;
        border: 2px solid #7d8390;
        transition: all ease 0.3s;
        &.selected {
          color: var(--fonts-primary);
          border-color: var(--fonts-primary);
        }
        .icon-tick {
          color: var(--fonts-primary);
          margin: 0;
          font-size: 0.65rem;
        }
        .MuiChip-label {
          line-height: normal;
        }
      }
    }
  }
}
