@use "./base/variables";
@use "./base/grid";
@use "./base/utils";
@use "./base/common";
@use "./base/header";
@use "./base/sidebar";
@use "./base/components/dashboard";
@use "./base/breadcrumb-filter";
@use "./base/components/calenderListings";
@use "./base/components/examination";
@import "react-big-calendar/lib/sass/styles";
@import "react-calendar/dist/Calendar.css";
@import "./base/components/quiz";
@import "./base/components/completedQuiz";
@import "./base/components/exams-stats-card";
@import "./base/components/tabs-secondary";
@import "./base/components/emptyBlock";
@import "./base/components/lectures";
@import "./base/components/sidebarFilter";
@import "./base/components/calender";
@import "./base/components/schedule-calendar";
@import "./base/components/login";
