.quiz-wrapper {
  .quiz-top-blk {
    background: #fff;
    padding: 35px 30px;
    border-radius: 6px;
    .MuiGrid-root {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        margin-bottom: 45px;
        @media (max-width: 767px) {
          margin-bottom: 25px;
        }
      }
      @media (max-width: 767px) {
        &:nth-child(5),
        &:nth-child(6) {
          margin-bottom: 25px;
        }
      }
    }
    .custom-label {
      h2 {
        font-weight: bold;
        color: var(--fonts-primary);
        font-size: 0.85rem;
        margin-bottom: 5px;
      }
      h3 {
        color: var(--fonts-primary);
        font-size: 0.85rem;
      }
    }
  }
  .instruction-blk {
    padding: 20px 24px 30px 24px;
    margin-bottom: 40px;
    h2 {
      font-weight: bold;
      font-size: 1.5rem;
      color: var(--fonts-primary);
      margin-bottom: 15px;
    }
    p {
      font-size: 1rem;
      color: var(--fonts-secondary);
      margin-bottom: 20px;
      line-height: 28px;
    }
  }
}
