.login-wrap {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    padding: 0 20px;
  }
  > div {
    flex: 1;
  }
  .bg-wrap {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #e5e5e5;
    .logo-wrap {
      width: 30%;
      position: absolute;
      left: 65px;
      top: 65px;
    }
  }
  .login-blk {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .login-content {
      max-width: 375px;
      form {
        max-width: 375px;
      }
      h2 {
        color: #2f2d22;
        font-size: 2.4rem;
        margin-bottom: 15px;
        font-weight: bold;
      }
      h3 {
        color: var(--fonts-primary);
        font-size: 0.85rem;
        margin-bottom: 50px;
      }
      h4 {
        color: #696969;
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .link-forget {
        color: #23417a;
        font-weight: 800;
        font-size: 0.75rem;
        margin-top: 10px;
        text-decoration: none;
        transition: all ease 0.3s;
        display: block;
        text-align: right;

        &:hover {
          color: var(--color-primary);
        }
      }
      .btn-submit {
        background: #23417a;
        border-radius: 4px;
        text-align: center;
        width: 100%;
        font-size: 1rem;
        color: #fff;
        font-weight: bold;
        border: 0;
        box-shadow: none;
        height: 68px;
        margin-top: 36px;
        text-transform: capitalize;
        transition: all ease 0.3s;
        @media (max-width: 767px) {
          height: 48px;
        }
        &:hover {
          background: var(--color-primary);
        }
      }
      .password-wrap {
        position: relative;
        .icon-box {
          opacity: 0;
          transition: all ease 0.3s;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          &.active {
            opacity: 1;
          }
          span {
            cursor: pointer;
          }
          .icon-password-hide {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
  .logo-wrap-mobile {
    display: none;
    margin-bottom: 50px;
    text-align: center;
    img {
      width: 200px;
    }
    @media (max-width: 767px) {
      display: block;
    }
  }
}
