.exam-not-active {
  flex-direction: column;
  margin-top: 100px;
  @media (max-width: 767px) {
    margin-top: 30px;
    text-align: center;
  }
  h2 {
    color: var(--fonts-secondary);
    font-size: 2rem;
    margin: 40px 0 10px 0;
    font-weight: bold;
  }
  h3 {
    color: var(--fonts-secondary);
    font-size: 0.85rem;
    margin: 0px 0 20px 0;
  }
  .time-blk {
    display: flex;
    align-items: center;
    .time {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: bold;
      font-size: 1.1rem;
      background: var(--fonts-primary);
      border-radius: 4px;
    }
    .dots {
      font-size: 2rem;
      color: var(--fonts-primary);
      font-weight: bold;
      margin: 0 15px;
    }
  }
}
