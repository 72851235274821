.exams-stats-card-wrap {
  @media (max-width: 768px) {
    .MuiGrid-item {
      min-width: 45% !important;
    }
  }
  @media (max-width: 767px) {
    .MuiGrid-item {
      min-width: 75% !important;
    }
  }
}
.exams-stats-card {
  background: #ffffff;
  border: 1px solid rgba(199, 224, 253, 0.43);
  border-radius: 9px;
  box-shadow: none;
  padding: 20px 24px;
  height: 100%;
  @media (max-width: 767px) {
    padding: 12px 16px;
  }
  .circle-graph {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 2px #fff;
    border-radius: 50%;
    position: absolute;
    line-height: 20px;
    padding: 0 5px;
    @media (max-width: 767px) {
      transform: scale(0.65);
    }
    h2 {
      font-size: 0.62rem;
      color: #fff;
      font-weight: bold;
      text-align: center;
      line-height: 16px;
      text-transform: capitalize;
    }
  }
  .graph-title {
    color: var(--fonts-primary);
    font-size: 1.1rem;
    font-weight: 800;
    margin-bottom: 10px;
  }
  .top-blk {
    min-height: 62px;
    @media (max-width: 767px) {
      min-height: 35px;
      .star-icon img {
        height: 35px;
        width: auto;
      }
    }
  }
  .info {
    h2 {
      color: #1c8c7d;
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 5px;
    }
    h3 {
      color: var(--fonts-primary);
      font-size: 0.9rem;
      font-weight: 400;
      margin-bottom: 20px;
      span {
        color: #1c8c7d;
        font-weight: bold;
        font-size: 0.4rem;
      }
    }
    h4 {
      color: var(--fonts-primary);
      font-size: 1.1rem;
      margin-bottom: 5px;
      font-weight: bold;
    }
    p {
      color: var(--fonts-gray);
      font-size: 0.85rem;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .bottom-scale {
    display: flex;
    width: 60%;
    justify-content: center;
    flex-direction: column;
    margin: 60px auto 0 auto;
    @media (max-width: 767px) {
      margin: 20px auto 0 auto;
    }
    .bars > div {
      height: 5px;
      width: 25%;
      margin-right: 2px;
      &:first-of-type {
        border-radius: 2px 0px 0px 2px;
      }
      &:last-of-type {
        border-radius: 0 2px 2px 0;
        margin: 0;
      }
    }
    .label-blk {
      margin-top: 5px;
      span {
        color: #788093;
        font-size: 0.75rem;
      }
    }
  }
  .strength-tooltip {
    width: 200px;
    .MuiTooltip-tooltip {
      background: #fff;
      border-radius: 4px;
      padding: 10px 10px 6px 10px;
      border: 0.2px solid rgb(120 128 147 / 16%);
      box-shadow: 0px 1px 24px rgb(224 224 224 / 46%);
      margin: 0 !important;

      .MuiTooltip-arrow {
        color: #fff;
      }

      h2 {
        font-size: 0.75rem;
        color: var(--fonts-primary);
        font-weight: bold;
        margin-bottom: 5px;
      }

      p {
        font-size: 0.75rem;
        color: var(--fonts-light);
        line-height: 16px;
      }

      .btn-wrap {
        text-align: right;
        margin-top: 5px;
      }

      button {
        font-size: 0.75rem;
        color: var(--fonts-primary);
        font-weight: bold;
        text-transform: capitalize;
        font-weight: normal;
        padding: 0;

        &:hover {
          color: var(--fonts-light);
        }
      }
    }
  }
  .stength-graph {
    position: relative;
    height: 220px;
  }
}
