.examination {
  .examination-top-blk {
    h2 {
      font-size: 1rem;
      font-weight: bold;
      color: var(--fonts-primary);
      margin-bottom: 10px;
    }

    span.MuiTypography-caption {
      font-size: 0.75rem;
      font-weight: 400;
      color: var(--fonts-gray);
    }

    h3 {
      font-size: 0.85rem;
      font-weight: 500;
      color: var(--fonts-gray);
    }
  }
  .MuiAccordion-root {
    box-shadow: none;
    background: #fff;
    border-radius: 4px;
    padding: 0 24px;
    &.Mui-expanded {
      &:after {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        content: "";
        height: 70px;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.26) 0%,
          #ffffff 100%
        );
      }
    }

    &:before {
      display: none;
    }
    .MuiAccordionSummary-root {
      padding: 15px 0 10px 0;
      min-height: auto;
      .MuiAccordionSummary-content {
        margin: 0;
        .MuiTypography-root {
          font-size: 1.5rem;
          color: var(--fonts-primary);
          font-weight: bold;
        }
      }
      .MuiSvgIcon-root {
        color: var(--fonts-primary);
      }
    }
    .MuiCollapse-root {
      .MuiAccordionDetails-root {
        padding: 0 0 10px 0;
        margin-top: 10px;
        p {
          font-size: 1rem;
          line-height: 24px;
          color: var(--fonts-secondary);
        }
      }
    }
  }
  .quest-top-head {
    font-size: 1.3rem;
    font-weight: bold;
    color: var(--fonts-primary);
  }
  .timer-wrap {
    position: fixed;
    right: 36px;
    top: 50%;
    transform: translateY(-50%);
    animation: 2s infinite fade;
    .timer {
      width: 66px;
      height: 66px;
      border-radius: 50%;
      background: var(--fonts-primary);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #fff;
      h3 {
        font-size: 0.75rem;
        font-weight: 500;
        margin-top: 5px;
      }
    }
  }
}
