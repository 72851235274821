.filter-top-wrap {
  margin-bottom: 36px;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
  .breadcrumb-wrap {
    h2.header-title {
      font-size: 2rem;
      font-weight: bold;
      color: var(--fonts-primary);
      margin-bottom: 10px;
      text-transform: capitalize;
      @media (max-width: 767px) {
        font-size: 1.5rem;
      }
    }
    h3.caption {
      font-size: 0.85rem;
      font-weight: 400;
      color: #788093;
      margin: 0;
    }
    nav {
      ol {
        li {
          font-size: 0.85rem;
          letter-spacing: 0.2px;
          text-transform: capitalize;
          color: var(--fonts-secondary);
          margin: 0;
          padding: 0 5px 0 0;
          &:last-of-type {
            color: var(--fonts-primary);
          }
        }
      }
    }
  }
}
