.completed-quiz-wrap {
  .completed-quiz-top-blk {
    background: #fff;
    padding: 35px 30px;
    border-radius: 6px;
    @media (max-width: 767px) {
      padding: 20px 20px 10px 20px;
    }
    .MuiGrid-root .MuiGrid-item {
      &:nth-child(1) .custom-label,
      &:nth-child(2) .custom-label,
      &:nth-child(3) .custom-label,
      &:nth-child(4) .custom-label {
        margin-bottom: 45px;
      }
      @media (max-width: 767px) {
        .custom-label {
          margin-bottom: 20px !important;
        }

        &:nth-child(7) .custom-label,
        &:nth-child(8) .custom-label {
          margin-bottom: 0 !important;
        }
      }
    }
    .custom-label {
      h2 {
        font-weight: bold;
        color: var(--fonts-primary);
        font-size: 0.85rem;
        margin-bottom: 5px;
      }
      h3 {
        color: var(--fonts-primary);
        font-size: 0.85rem;
      }
    }
  }
}
