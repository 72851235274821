@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?kdimtu');
  src:  url('fonts/icomoon.eot?kdimtu#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?kdimtu') format('truetype'),
    url('fonts/icomoon.woff?kdimtu') format('woff'),
    url('fonts/icomoon.svg?kdimtu#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-password-hide:before {
  content: "\e91c";
  color: #999;
}
.icon-password-show:before {
  content: "\e91d";
  color: #999;
}
.icon-tick:before {
  content: "\e919";
}
.icon-video:before {
  content: "\e91b";
}
.icon-trenddown:before {
  content: "\e916";
}
.icon-trendup:before {
  content: "\e917";
}
.icon-trendflat:before {
  content: "\23AF";
}
.icon-assignment1:before {
  content: "\e913";
}
.icon-Dashboard:before {
  content: "\e914";
}
.icon-exam-Quiz:before {
  content: "\e915";
}
.icon-recomendation:before {
  content: "\e91a";
}
.icon-art1:before {
  content: "\e912";
}
.icon-audios:before {
  content: "\e918";
}
.icon-computer-science:before {
  content: "\e91e";
}
.icon-deadline:before {
  content: "\e91f";
}
.icon-english:before {
  content: "\e920";
}
.icon-lectures:before {
  content: "\e921";
}
.icon-lecture:before {
  content: "\e921";
}
.icon-marks:before {
  content: "\e922";
}
.icon-math:before {
  content: "\e923";
}
.icon-notf:before {
  content: "\e924";
}
.icon-pdfs:before {
  content: "\e925";
}
.icon-science1:before {
  content: "\e926";
}
.icon-score:before {
  content: "\e927";
}
.icon-setting:before {
  content: "\e928";
}
.icon-start:before {
  content: "\e92c";
}
.icon-students1:before {
  content: "\e92d";
}
.icon-calendar:before {
  content: "\e930";
}
.icon-course1:before {
  content: "\e931";
}
.icon-art:before {
  content: "\e900";
}
.icon-assignment:before {
  content: "\e901";
}
.icon-bell:before {
  content: "\e902";
}
.icon-category:before {
  content: "\e903";
}
.icon-computer:before {
  content: "\e904";
}
.icon-course:before {
  content: "\e905";
}
.icon-dashboard:before {
  content: "\e906";
}
.icon-down:before {
  content: "\e907";
}
.icon-drag:before {
  content: "\e908";
}
.icon-filter:before {
  content: "\e909";
}
.icon-grade:before {
  content: "\e90a";
}
.icon-hamburger:before {
  content: "\e90b";
}
.icon-languages:before {
  content: "\e90c";
}
.icon-maths:before {
  content: "\e90d";
}
.icon-plus:before {
  content: "\e90e";
}
.icon-question:before {
  content: "\e90f";
}
.icon-science:before {
  content: "\e910";
}
.icon-students:before {
  content: "\e911";
}
