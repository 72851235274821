.lecture-view-detail {
  background: #fff;
  border-radius: 0px 0px 4px 4px;
  .tab-content {
    p {
      font-size: 0.85rem;
      color: #788093;
      line-height: 20px;
    }
    h2 {
      font-size: 1.1rem;
      color: var(--fonts-primary);
      font-weight: 800;
      margin-bottom: 10px;
    }
    h3 {
      font-size: 0.85rem;
      color: var(--fonts-primary);
      font-weight: 800;
      margin-bottom: 10px;
    }
  }
}
.play-list-wrapper {
  background: #fff;
  border-radius: 4px;
  height: 100%;
  .lecture-playlist {
    padding: 16px;
    .list-item {
      background: #fff;
      border-bottom: 1px solid rgba(199, 224, 253, 0.4);
      padding: 10px 0;
      transition: all ease 0.3s;
      position: relative;
      &:last-of-type {
        border: 0;
      }
      &.watching {
        &::before {
          content: "";
          position: absolute;
          left: -16px;
          height: 100%;
          top: 0;
          width: calc(100% + 32px);
          background: linear-gradient(270deg, #fff8eb 0%, #fcfaf6 104.32%);
        }
      }
      .icon-box {
        margin-right: 10px;
        z-index: 1;
        &.watched {
          svg {
            fill: #1c8c7d;
          }
        }
        svg {
          width: 18px;
        }
      }
      .desc-box {
        width: calc(100% - 25px);
        z-index: 1;
        h2 {
          font-size: 0.85rem;
          color: var(--fonts-primary);
          font-weight: 500;
          text-transform: capitalize;
          margin-bottom: 5px;
        }
        h3 {
          font-size: 0.65rem;
          color: #b9bcc1;
          font-weight: 300;
          text-transform: capitalize;
        }
      }
    }
  }
}
audio {
  width: 100% !important;
}
