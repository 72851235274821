.tabs-secondary {
  border: 1px solid rgba(224, 224, 224, 0.71);
  border-radius: 5px;
  margin-bottom: 32px;
  background: #fff;
  .MuiTab-root {
    padding: 22px 30px;
    font-size: 1rem;
    text-transform: capitalize;
    font-weight: 500;
    color: var(--fonts-primary);
    line-height: 22px;
    transition: all ease 0.3s;
    position: relative;
    @media (max-width: 767px) {
      min-width: auto;
      padding: 10px 6px;
      font-size: 0.85rem;
      .count {
        margin-left: 2px !important;
      }
    }
    .count {
      background: var(--fonts-primary);
      color: #fff;
      font-size: 0.75rem;
      font-weight: 300;
      margin-left: 5px;
      padding: 0;
      display: inline-flex;
      min-width: 16px;
      height: 16px;
      justify-content: center;
      align-items: center;
      padding: 2px 2px 0 2px;
    }
    &::after {
      position: absolute;
      bottom: 0;
      transition: all ease 0.3s;
      width: 0;
      left: 0;
      height: 3px;
      background: var(--fonts-primary);
      content: "";
    }
    &:hover,
    &.Mui-selected {
      font-weight: bold;
      color: var(--fonts-primary);
      &::after {
        width: 100%;
      }
    }
  }
  .MuiTabs-indicator {
    display: none;
  }
}
